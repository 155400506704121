<div>
  @for (invoice of invoiceService.invoices$ | async; track 'id') {
    <app-invoice-details [invoice]="invoice"></app-invoice-details>
  }
</div>
<div class="my-4">
  <button
    size="s"
    icon="tuiIconPlus"
    tuiButton
    (click)="invoiceService.addInvoice()"
  >
    Добавить инвойс
  </button>
</div>
