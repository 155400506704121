<div class="detail-page">
  <div class="sticky top-0 z-10 app-document-header py-4">
    <app-main-info></app-main-info>
  </div>
  <div class="detail-page__container">
    <div class="my-4">
      <app-invoices></app-invoices>
    </div>
    <div class="my-4">
      <app-invoices-total></app-invoices-total>
    </div>
  </div>
  <div class="sticky bottom-0 z-10 app-document-header py-4">
    <div class="max-w-[1400px] mx-auto flex">
      <div class="w-full flex justify-end">
        <div class="self-end">
          <app-scroll-top-button></app-scroll-top-button>
        </div>
      </div>
    </div>
  </div>
</div>
