import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { TuiInputDateModule } from '@taiga-ui/kit';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { BehaviorSubject } from 'rxjs';
import { NativeDateTransformerDirective } from '@shared/directives/native-date-transformer.directive';

@Component({
  selector: 'app-input-date',
  standalone: true,
  imports: [
    TuiInputDateModule,
    NativeDateTransformerDirective,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateComponent),
      multi: true,
    },
  ],
  templateUrl: './input-date.component.html',
  styleUrl: './input-date.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateComponent implements ControlValueAccessor, OnInit {
  formControl: FormControl;
  lastValue$ = new BehaviorSubject<string>('');
  @Input({ required: false })
  label = '';

  @Input({ required: false })
  size: 'm' | 'l' = 'm';

  @Input({ required: false })
  labelOutside = true;

  constructor(
    private readonly injector: Injector,
    @Attribute('class') public styleClass: string,
  ) {}

  onFocusChange(focused: boolean) {
    if (!focused && this.lastValue$.value) {
      const value = this.lastValue$.value;
      const [day, month, year] = value.split('.');
      const yearNumeric = Number.parseInt(year, 10);
      let prefix = '';
      if (value && year.length === 2) {
        if (yearNumeric >= 0 && yearNumeric <= 50) {
          prefix = '20';
        } else if (yearNumeric >= 51 && yearNumeric <= 99) {
          prefix = '19';
        }
        const date = `${prefix}${year}-${month}-${day}`;
        this.formControl.setValue(date);
        this.formControl.markAsDirty();
      }
    }
  }

  onInputChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.lastValue$.next(value);
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  setDisabledState(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(): void {}

  ngOnInit() {
    const ngControl = this.injector.get(NgControl);
    this.formControl = (ngControl as FormControlDirective).form;
  }
}
