import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { TuiCheckboxLabeledModule, TuiCheckboxModule } from '@taiga-ui/kit';

@Component({
  selector: 'app-checkbox-labeled',
  standalone: true,
  imports: [
    TuiCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    TuiCheckboxLabeledModule,
  ],
  templateUrl: './checkbox-labeled.component.html',
  styleUrl: './checkbox-labeled.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxLabeledComponent),
      multi: true,
    },
  ],
})
export class CheckboxLabeledComponent implements ControlValueAccessor, OnInit {
  formControl: FormControl;

  constructor(private readonly injector: Injector) {}

  onChange: any = () => {};
  onTouch: any = () => {};
  ngOnInit() {
    const ngControl = this.injector.get(NgControl);
    this.formControl = (ngControl as FormControlDirective).form;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(): void {}
}
