import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TuiIslandModule } from '@taiga-ui/kit';
import { AsyncPipe, NgForOf } from '@angular/common';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { TuiLetModule } from '@taiga-ui/cdk';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { roundDecimal } from '@shared/utils/math';
import { InvoiceService } from '../../services/invoice.service';
import { InvoiceDetails } from '../../types';
import { cargoGroupsMap } from '../../catalogs';

type InvoiceByClient = Pick<InvoiceDetails, 'client' | 'cost' | 'quantity'> & {
  currencyLetter: string;
};
@Component({
  selector: 'app-invoices-total',
  standalone: true,
  imports: [TuiIslandModule, AsyncPipe, NgForOf, TuiTableModule, TuiLetModule],
  templateUrl: './invoices-total.component.html',
  styleUrl: './invoices-total.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesTotalComponent {
  tableHeaders: Array<{ name: string; value: keyof InvoiceByClient }> = [
    {
      name: 'Клиент',
      value: 'client',
    },
    {
      name: 'Кол-во мест',
      value: 'quantity',
    },
    {
      name: 'Ставка ТО',
      value: 'cost',
    },
  ];

  totalCost$ = new BehaviorSubject(0);
  totalQuantity$ = new BehaviorSubject(0);

  invoicesByClient$: Observable<InvoiceByClient[]> =
    this.invoiceService.invoices$.pipe(
      map(invoices => {
        const clients: Record<
          string,
          {
            client: string;
            quantity: number;
            cost: number;
            currencyLetter: string;
          }
        > = {};

        const total = invoices.reduce((prev, curr) => {
          const currencyLetter =
            cargoGroupsMap.get(curr.group)?.currencyLetter ?? '';
          const client = curr.client || '(без названия)';
          const type = `${client}[${currencyLetter}]`;
          return {
            ...prev,
            [type]: {
              client,
              cost: (prev[type]?.cost ?? 0) + curr.cost,
              quantity: (prev[type]?.quantity ?? 0) + (curr.quantity ?? 0),
              currencyLetter,
            },
          };
        }, clients);

        return Object.entries(total).map(([clientAndCurrency, value]) => {
          return {
            client: value.client,
            quantity: roundDecimal(value.quantity),
            cost: roundDecimal(value.cost),
            currencyLetter: value.currencyLetter,
          };
        });
      }),
    );

  constructor(private readonly invoiceService: InvoiceService) {}

  get columns() {
    return [...this.tableHeaders.map(c => c.value)];
  }
}
