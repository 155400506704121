<tui-input-date
  [class]="styleClass"
  appToNativeDate
  [formControl]="formControl"
  [tuiTextfieldSize]="size"
  [tuiTextfieldLabelOutside]="labelOutside"
  (input)="onInputChange($event)"
  (focusedChange)="onFocusChange($event)"
>
  {{ label }}
</tui-input-date>
