import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ScrollTopButtonComponent } from '@shared/components/scroll-top-button/scroll-top-button.component';
import { MainInfoComponent } from './components/main-info/main-info.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { InvoicesTotalComponent } from './components/invoices-total/invoices-total.component';

@Component({
  selector: 'app-rate-calculator',
  standalone: true,
  imports: [
    ScrollTopButtonComponent,
    MainInfoComponent,
    InvoicesComponent,
    InvoicesTotalComponent,
  ],
  templateUrl: './rate-calculator.component.html',
  styleUrl: './rate-calculator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RateCalculatorComponent {}
