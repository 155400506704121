import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TuiGroupModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import { InputDateComponent } from '@shared/components/forms/input-date/input-date.component';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { TRAILERS, VEHICLES } from '../../catalogs';

@Component({
  selector: 'app-main-info',
  standalone: true,
  imports: [
    TuiGroupModule,
    TuiInputModule,
    TuiInputNumberModule,
    InputDateComponent,
    AutocompleteComponent,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './main-info.component.html',
  styleUrl: './main-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainInfoComponent implements OnInit {
  form = this.fb.group({
    id: this.fb.control<Nullable<number>>({ value: 1, disabled: true }),
    date: this.fb.control({ value: '', disabled: true }),
    vehicle: this.fb.control({ value: 'Авто', disabled: true }),
    trailerType: this.fb.control({ value: 'Тент', disabled: true }),
  });

  readonly vehicles = VEHICLES;
  readonly trailers = TRAILERS;
  constructor(private readonly fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.patchValue({
      date: moment().format('YYYY-MM-DD'),
    });
  }
}
