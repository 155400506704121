import { Directive } from '@angular/core';
import { TUI_DATE_VALUE_TRANSFORMER } from '@taiga-ui/kit';
import { DateTransformer } from './date-transformer';

@Directive({
  selector: 'tui-input-date[appToNativeDate]',
  providers: [
    {
      provide: TUI_DATE_VALUE_TRANSFORMER,
      useClass: DateTransformer,
    },
  ],
  standalone: true,
})
export class NativeDateTransformerDirective {}
