import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  TuiInputModule,
  TuiInputNumberModule,
  TuiIslandModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { AsyncPipe, JsonPipe, NgForOf } from '@angular/common';
import { TuiTableModule } from '@taiga-ui/addon-table';
import {
  BehaviorSubject,
  delay,
  filter,
  map,
  merge,
  Observable,
  shareReplay,
  startWith,
  Subject,
  tap,
} from 'rxjs';
import { TuiActiveZoneModule, TuiForModule, TuiLetModule } from '@taiga-ui/cdk';
import { roundDecimal } from '@shared/utils/math';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CargoPlace, CargoPlaceForm, InvoiceDetails } from '../../types';
import { CargoPlaceComponent } from './components/cargo-place/cargo-place.component';
import {
  CARGO_GROUPS,
  cargoGroupsMap,
  Currencies,
  PalletType,
  palletTypesMap,
} from '../../catalogs';
import { InvoiceService } from '../../services/invoice.service';

type GroupedPallet = {
  pallet: string;
  quantity: number;
  cost: number;
};

@Component({
  selector: 'app-invoice-details',
  standalone: true,
  imports: [
    TuiIslandModule,
    TuiInputModule,
    ReactiveFormsModule,
    TuiToggleModule,
    AutocompleteComponent,
    TuiTextfieldControllerModule,
    CargoPlaceComponent,
    NgForOf,
    TuiTableModule,
    TuiForModule,
    AsyncPipe,
    TuiLetModule,
    TuiInputNumberModule,
    TuiActiveZoneModule,
    JsonPipe,
  ],
  templateUrl: './invoice-details.component.html',
  styleUrl: './invoice-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailsComponent implements OnInit {
  invoiceForm = this.fb.nonNullable.group({
    id: '',
    invoice: '',
    client: '',
    farm: '',
    import: this.fb.nonNullable.control({ value: false, disabled: true }),
    group: '',
    places: this.fb.array<FormGroup<CargoPlaceForm>>([]),
    cost: 0,
    quantity: 0,
    comment: '',
    grossWeight: 0,
  });

  readonly cargoGroups = CARGO_GROUPS;
  tableHeaders: Array<{ name: string; value: keyof GroupedPallet }> = [
    {
      name: '',
      value: 'pallet',
    },
    {
      name: '',
      value: 'quantity',
    },
    {
      name: '',
      value: 'cost',
    },
  ];

  currencyLetter$ = this.invoiceForm.controls.group.valueChanges.pipe(
    startWith(this.invoiceForm.controls.group.value),
    map(type => cargoGroupsMap.get(type)?.currencyLetter ?? ''),
    shareReplay(),
  );

  update$ = new Subject<void>();
  totalCost$ = new BehaviorSubject(0);
  invoice$ = new BehaviorSubject<Nullable<InvoiceDetails>>(null);

  groupedPallets$: Observable<GroupedPallet[]> = merge(
    this.invoiceForm.controls.places.valueChanges,
    this.invoiceForm.controls.import.valueChanges,
    this.invoiceForm.controls.group.valueChanges,
    this.invoiceForm.controls.grossWeight.valueChanges,
  ).pipe(
    map(() => this.invoiceForm.getRawValue()),
    map(invoice => {
      const places = invoice.places ?? [];
      const groupRate = cargoGroupsMap.get(invoice.group ?? '')?.rate ?? 0;
      const isImport = invoice.import;
      const total = this.getQuantityByPalletType(places);

      const pallets: GroupedPallet[] = Object.entries(total).map(
        ([pallet, quantity]) => {
          const limit = palletTypesMap.get(pallet)?.limitPerTrailer ?? 0;
          const cost = (groupRate / limit) * quantity;
          return {
            pallet,
            quantity: roundDecimal(quantity),
            cost: isImport
              ? 0
              : Number.isNaN(cost) || !Number.isFinite(cost)
                ? 0
                : roundDecimal(cost),
          };
        },
      );
      return pallets;
    }),
    shareReplay(),
  );

  isActiveZone$ = new BehaviorSubject(false);
  constructor(
    private readonly invoiceService: InvoiceService,
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
  ) {}

  onCreatePlace() {
    const isImport = this.invoiceForm.controls.import.getRawValue();
    const value = {
      pallet: isImport ? PalletType.US : '',
      hasBox: false,
      packageType: '',
      packageQuantity: 0,
      quantity: 0,
    };
    this.invoiceForm.controls.places.push(this.fb.nonNullable.group(value));
  }

  onRemovePlace(index: number) {
    this.invoiceForm.controls.places.removeAt(index);
  }

  onZoneChange(focused: boolean) {
    if (!focused) {
      this.update$.next();
    }
    this.isActiveZone$.next(focused);
  }

  onUpdate() {
    this.update$
      .pipe(
        delay(0),
        filter(() => !this.invoiceForm.pristine),
        tap(() => {
          this.invoiceService.updateInvoice(this.invoiceForm.getRawValue());
          this.invoiceForm.markAsUntouched();
          this.invoiceForm.markAsPristine();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onPalletsChange() {
    this.groupedPallets$
      .pipe(
        filter(() => this.invoiceForm.dirty),
        tap(groupedPallets => {
          this.updateTotalCost(groupedPallets);
          this.updateTotalQuantity(groupedPallets);
        }),
      )
      .subscribe();
  }

  onInputChange() {
    this.invoiceForm.controls.import.valueChanges
      .pipe(
        tap(value => {
          if (value) {
            const placesControl = this.invoiceForm.controls.places;
            const onlyUsPallet = placesControl
              .getRawValue()
              .filter(place => place.pallet === PalletType.US);
            placesControl.clear();
            onlyUsPallet.forEach(place =>
              placesControl.push(this.fb.nonNullable.group(place)),
            );
            if (!placesControl.length) {
              this.onCreatePlace();
            }
          } else {
            this.invoiceForm.controls.grossWeight.setValue(0);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onGroupChange() {
    this.invoiceForm.controls.group.valueChanges
      .pipe(
        tap(group => {
          const importControl = this.invoiceForm.controls.import;
          const currencyLetter = cargoGroupsMap.get(group)?.currencyLetter;
          if (currencyLetter === Currencies.USD) {
            importControl.enable();
          } else {
            importControl.disable();
            importControl.setValue(false);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  getQuantityByPalletType(places: CargoPlace[]) {
    const typeQuantity: Record<string, number> = {};

    return places.reduce((prev, curr) => {
      const type = curr.pallet || '(без типа)';
      return {
        ...prev,
        [type]: (prev[type] ?? 0) + (curr.quantity ?? 0),
      };
    }, typeQuantity);
  }

  updateTotalCost(groupedPallets: GroupedPallet[]) {
    const isImport = this.invoiceForm.controls.import.getRawValue();
    const grossWeight = this.invoiceForm.controls.grossWeight.getRawValue();

    const totalCost = isImport
      ? roundDecimal(grossWeight)
      : roundDecimal(
          groupedPallets.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.cost;
          }, 0),
        );
    this.totalCost$.next(totalCost);

    this.invoiceForm.controls.cost.setValue(totalCost, {
      emitEvent: false,
    });
  }

  updateTotalQuantity(groupedPallets: GroupedPallet[]) {
    const totalQuantity = roundDecimal(
      groupedPallets.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.quantity;
      }, 0),
    );
    this.invoiceForm.controls.quantity.setValue(totalQuantity, {
      emitEvent: false,
    });
  }

  get columns() {
    return [...this.tableHeaders.map(c => c.value)];
  }

  ngOnInit() {
    this.onUpdate();
    this.onPalletsChange();
    this.onInputChange();
    this.onGroupChange();
  }

  @Input() set invoice(invoice: InvoiceDetails) {
    this.invoice$.next(invoice);
    const { places, ...rest } = invoice;
    this.invoiceForm.patchValue(rest);
    const control = this.invoiceForm.get('places') as FormArray;
    control.clear();
    places.forEach(place => {
      control.push(this.fb.nonNullable.group(place), { emitEvent: false });
    });
  }
}
