import { AbstractTuiValueTransformer, TuiDay } from '@taiga-ui/cdk';
import { Injectable } from '@angular/core';

type From = TuiDay | null;

type To = string | null;

@Injectable()
export class DateTransformer extends AbstractTuiValueTransformer<From, To> {
  fromControlValue(controlValue: To): From {
    if (!controlValue) {
      return null;
    }
    const date = TuiDay.parseRawDateString(controlValue.toString(), 'YMD');
    return new TuiDay(date.year, date.month, date.day);
  }

  toControlValue(componentValue: From): To {
    if (!componentValue) {
      return null;
    }
    return componentValue?.getFormattedDay('YMD', '-');
  }
}
