export type Pallet = {
  name: string;
  value: string;
  widthX: number;
  widthY: number;
  limitPerTrailer: number;
};

export type CargoGroup = {
  name: string;
  value: string;
  rate: number;
  currencyLetter: string;
};

export const VEHICLES = [
  {
    name: 'Авто',
    value: 'Авто',
  },
];

export const TRAILERS = [
  {
    name: 'Тент',
    value: 'Тент',
  },
];

export enum Currencies {
  USD = 'USD',
  EUR = 'EUR',
}
export const CARGO_GROUPS: CargoGroup[] = [
  {
    name: 'Срез (30000)',
    value: 'Срез',
    rate: 30000,
    currencyLetter: Currencies.USD,
  },
  {
    name: 'Горшки (20000)',
    value: 'Горшки',
    rate: 20000,
    currencyLetter: Currencies.USD,
  },
  {
    name: 'Луковицы (25000)',
    value: 'Луковицы',
    rate: 25000,
    currencyLetter: Currencies.EUR,
  },
  {
    name: 'Уличные (15000)',
    value: 'Уличные',
    rate: 15000,
    currencyLetter: Currencies.EUR,
  },
];

export enum PalletType {
  US = 'Паллет US',
  EUR = 'Паллет EUR',
  FIN = 'Паллет FIN',
  CART = 'тележка',
}
export const PALLET_TYPES: Pallet[] = [
  {
    name: 'Паллет US',
    value: PalletType.US,
    widthX: 1.2,
    widthY: 1.2,
    limitPerTrailer: 22,
  },
  {
    name: 'Паллет EUR',
    value: PalletType.EUR,
    widthX: 1.2,
    widthY: 0.8,
    limitPerTrailer: 33,
  },
  {
    name: 'Паллет FIN',
    value: PalletType.FIN,
    widthX: 1,
    widthY: 1.2,
    limitPerTrailer: 26,
  },
  {
    name: 'тележка',
    value: PalletType.CART,
    widthX: 1.35,
    widthY: 1.2,
    limitPerTrailer: 43,
  },
];

export const PACKAGE_TYPES = [
  {
    name: '864',
    value: '864',
    widthX: 1.2,
    widthY: 0.4,
    limitPerPallet: 476,
  },
  {
    name: 'Box AA Special N',
    value: '3AAN',
    widthX: 1.2,
    widthY: 0.6,
    limitPerPallet: 333,
  },
  {
    name: 'Salal Box',
    value: 'SA',
    widthX: 1.2,
    widthY: 0.4,
    limitPerPallet: 204,
  },
];

export const palletTypesMap: Map<string, Pallet> = PALLET_TYPES.reduce(
  (previousValue, currentValue) => {
    return previousValue.set(currentValue.value, currentValue);
  },
  new Map(),
);

export const cargoGroupsMap: Map<string, CargoGroup> = CARGO_GROUPS.reduce(
  (previousValue, currentValue) => {
    return previousValue.set(currentValue.value, currentValue);
  },
  new Map(),
);
