<tui-island
  #card="tuiActiveZone"
  (tuiActiveZoneChange)="onZoneChange($event)"
  class="bg-neutral-100 mb-2 border-green-500"
  [class.border-2]="isActiveZone$ | async"
>
  <div class="flex gap-x-2">
    <tui-input
      class="w-1/12"
      [formControl]="invoiceForm.controls.invoice"
      tuiTextfieldSize="m"
      >Инвойс</tui-input
    >
    <tui-input
      class="w-2/12"
      [formControl]="invoiceForm.controls.client"
      tuiTextfieldSize="m"
      >Клиент</tui-input
    >
    <tui-input
      class="w-2/12"
      [formControl]="invoiceForm.controls.farm"
      tuiTextfieldSize="m"
      >Питомник</tui-input
    >
    <app-autocomplete
      class="w-2/12"
      [formControl]="invoiceForm.controls.group"
      [items]="cargoGroups"
      [itemText]="'name'"
      [itemValue]="'value'"
      label="Группа товаров"
      [labelOutside]="false"
    ></app-autocomplete>
    <div class="w-2/12 flex items-center">
      <tui-toggle
        [formControl]="invoiceForm.controls.import"
        size="m"
        class="mx-2"
      ></tui-toggle>
      <span class="text-sm">Импорт</span>
    </div>
    @if (invoiceForm.controls.import.value) {
      <tui-input-number
        class="w-2/12"
        [formControl]="invoiceForm.controls.grossWeight"
        tuiTextfieldSize="m"
        tuiTextfieldPostfix="КГ"
        >Вес брутто
      </tui-input-number>
    }
  </div>
  <div class="my-10">
    <app-cargo-place
      [tuiActiveZoneParent]="card"
      *ngFor="
        let place of invoiceForm.controls.places.controls;
        let index = index;
        let last = last
      "
      [place]="place"
      [isImport]="invoiceForm.controls.import.getRawValue()"
      [last]="last"
      [index]="index"
      (create)="onCreatePlace()"
      (remove)="onRemovePlace($event)"
    ></app-cargo-place>
  </div>

  <ng-container *tuiLet="currencyLetter$ | async as currencyLetter">
    <div class="flex" *tuiLet="groupedPallets$ | async as pallets">
      <table
        tuiTable
        class="plain-table w-1/2 overflow-x-auto px-2 cursor-pointer"
        [columns]="columns"
      >
        <tbody tuiTbody>
          <tr *ngFor="let pallet of pallets" tuiTr>
            @for (header of tableHeaders; track 'value') {
              <td width="100px" *tuiCell="header.value" tuiTd>
                {{ pallet[header.value] }}
              </td>
            }
            <td width="100px" *tuiCell="'cost'" tuiTd>
              {{ pallet.cost }} {{ currencyLetter }}
            </td>
          </tr>
        </tbody>
        @if (pallets?.length) {
          <tfoot>
            <tr>
              <td tuiTd colspan="2"></td>
              <td tuiTd>
                <b>{{ totalCost$ | async }} {{ currencyLetter }}</b>
              </td>
            </tr>
          </tfoot>
        }
      </table>
    </div>
  </ng-container>
</tui-island>
