import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TuiButtonModule } from '@taiga-ui/core';
import { AsyncPipe } from '@angular/common';
import { InvoiceService } from '../../services/invoice.service';
import { InvoiceDetailsComponent } from '../invoice-details/invoice-details.component';

@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [TuiButtonModule, AsyncPipe, InvoiceDetailsComponent],
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesComponent implements OnInit {
  constructor(protected readonly invoiceService: InvoiceService) {}

  ngOnInit() {
    this.invoiceService.addInvoice();
  }
}
