import { Routes } from '@angular/router';
import { KANBAN_PAGE } from './route-names';
import { DocumentLayoutComponent } from './core/layouts/document-layout/document-layout.component';
import { RateCalculatorComponent } from './modules/rate-calculator/rate-calculator.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'calculator',
    pathMatch: 'full',
  },
  //   children: [
  //     {
  //       path: MAIN_PAGE,
  //       component: MainPageComponent,
  //     },
  //     {
  //       path: LOGIN_PAGE,
  //       component: LoginFormComponent,
  //     },
  //     {
  //       path: FORBIDDEN_PAGE,
  //       component: ForbiddenPageComponent,
  //     },
  //   ],
  // },
  {
    path: '',
    component: DocumentLayoutComponent,
    children: [
      {
        path: 'calculator',
        component: RateCalculatorComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: 'calculator',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: KANBAN_PAGE,
  },
];
