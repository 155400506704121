import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  DestroyRef,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { CheckboxLabeledComponent } from '@shared/components/forms/checkbox-labeled/checkbox-labeled.component';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiGroupModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { Observable, startWith, tap, combineLatest } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { roundDecimal } from '@shared/utils/math';
import { CargoPlaceForm } from '../../../../types';
import { PACKAGE_TYPES, PALLET_TYPES, PalletType } from '../../../../catalogs';

@Component({
  selector: 'app-cargo-place',
  standalone: true,
  imports: [
    AutocompleteComponent,
    CheckboxLabeledComponent,
    ReactiveFormsModule,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    TuiButtonModule,
    AsyncPipe,
    TuiGroupModule,
    TuiInputModule,
  ],
  templateUrl: './cargo-place.component.html',
  styleUrl: './cargo-place.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoPlaceComponent implements OnInit {
  @Input({ required: true }) place: FormGroup<CargoPlaceForm>;
  @Input({ required: true }) last: boolean;
  @Input({ required: true }) index: number;
  @Output() create = new EventEmitter<void>();
  @Output() remove = new EventEmitter<number>();
  readonly palletTypes = PALLET_TYPES;
  readonly packageTypes = PACKAGE_TYPES;
  hasBox$: Observable<boolean>;
  packageName = this.fb.control({ value: '', disabled: true });
  packageSize = this.fb.control({ value: '', disabled: true });
  packageLimit = this.fb.control<Nullable<number>>({
    value: null,
    disabled: true,
  });

  palletSize = this.fb.control({ value: '', disabled: true });
  palletLimit = this.fb.control<Nullable<number>>({
    value: null,
    disabled: true,
  });

  protected readonly PalletType = PalletType;
  constructor(
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
  ) {}

  onPackageTypeChange() {
    this.place.controls.packageType.valueChanges
      .pipe(
        tap(packageType => {
          this.updatePackageDetails(packageType);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onPalletTypeChange() {
    this.place.controls.pallet.valueChanges
      .pipe(
        tap(type => {
          this.updatePalletDetails(type);
          this.clearPackageDetailsIfCartSelected(type);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  @Input({ required: true }) set isImport(value: boolean) {
    const palletTypeControl = this.place.controls.pallet;
    if (value) {
      palletTypeControl.disable();
    } else {
      palletTypeControl.enable();
    }
  }

  clearPackageDetailsIfCartSelected(palletType: string) {
    if (palletType === PalletType.CART) {
      this.place.controls.hasBox.setValue(false);
      this.place.controls.packageType.setValue('');
      this.place.controls.packageQuantity.setValue(0);
    }
  }

  getCalculatedQuantity(
    limit: Nullable<number>,
    placeQuantity: Nullable<number>,
  ) {
    return limit && placeQuantity
      ? roundDecimal(placeQuantity / Number(limit))
      : 0;
  }

  calculatePlaceQuantity() {
    combineLatest([
      this.packageLimit.valueChanges,
      this.place.controls.packageQuantity.valueChanges,
    ])
      .pipe(
        tap(([packageLimit, placeQuantity]) => {
          const palletType = this.place.controls.pallet.getRawValue();
          if (palletType === PalletType.CART) {
            const palletLimitPerTrailer = this.palletLimit.getRawValue();
            this.place.controls.quantity.setValue(
              this.getCalculatedQuantity(palletLimitPerTrailer, placeQuantity),
            );
          } else {
            this.place.controls.quantity.setValue(
              this.getCalculatedQuantity(packageLimit, placeQuantity),
            );
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updatePackageDetails(packageType: string) {
    const packageDetails = PACKAGE_TYPES.find(p => p.value === packageType);
    this.packageName.setValue(packageDetails?.name ?? '');
    this.packageSize.setValue(
      packageDetails
        ? `${packageDetails?.widthX} x ${packageDetails?.widthY}`
        : '',
    );
    this.packageLimit.setValue(packageDetails?.limitPerPallet ?? null);
  }

  updatePalletDetails(palletType: string) {
    const pallet = PALLET_TYPES.find(p => p.value === palletType);
    this.palletSize.setValue(
      pallet ? `${pallet?.widthX} x ${pallet?.widthY}` : '',
    );
    this.palletLimit.setValue(pallet?.limitPerTrailer ?? null);
  }

  ngOnInit(): void {
    this.hasBox$ = this.place.controls.hasBox.valueChanges.pipe(
      startWith(this.place.controls.hasBox.getRawValue()),
    );
    this.calculatePlaceQuantity();
    this.onPackageTypeChange();
    this.onPalletTypeChange();

    const { packageType, pallet } = this.place.getRawValue();
    this.updatePalletDetails(pallet);
    this.updatePackageDetails(packageType);
  }
}
