import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { produce } from 'immer';
import { generateUUID } from '@shared/utils/common';
import { InvoiceDetails } from '../types';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  invoices$ = new BehaviorSubject<InvoiceDetails[]>([]);

  private createInvoice() {
    return {
      id: generateUUID(),
      invoice: '',
      client: '',
      farm: '',
      import: false,
      group: '',
      cost: 0,
      quantity: 0,
      comment: '',
      grossWeight: 0,
      places: [
        {
          pallet: '',
          hasBox: false,
          packageType: '',
          packageQuantity: 0,
          quantity: 0,
        },
      ],
    };
  }

  addInvoice() {
    const updatedDetails = produce(this.invoices$.value, draft => {
      draft.push(this.createInvoice());
    });
    this.invoices$.next(updatedDetails);
  }

  removeInvoice(index: number) {
    const updatedDetails = produce(this.invoices$.value, draft => {
      draft.splice(index, 1);
    });
    this.invoices$.next(updatedDetails);
  }

  updateInvoice(invoiceDetails: InvoiceDetails) {
    const index = this.invoices$.value.findIndex(
      details => details.id === invoiceDetails.id,
    );
    const updatedDetails = produce(this.invoices$.value, draft => {
      draft.splice(index, 1, invoiceDetails);
    });
    this.invoices$.next(updatedDetails);
  }
}
