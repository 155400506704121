<div class="flex mb-2 gap-x-2 items-center">
  <div tuiGroup [collapsed]="true" class="flex w-3/12">
    <app-autocomplete
      label="Паллет"
      [labelOutside]="false"
      class="tui-group__inherit-item w-6/12 tui-group__auto-width-item"
      componentClass="tui-group__inherit-item"
      [formControl]="place.controls.pallet"
      [items]="palletTypes"
      [itemValue]="'value'"
      [itemText]="'name'"
    ></app-autocomplete>
    <tui-input
      [formControl]="palletSize"
      class="w-4/12 tui-group__auto-width-item"
      tuiTextfieldSize="m"
      >Размер</tui-input
    >
    <tui-input
      [formControl]="palletLimit"
      class="w-2/12 tui-group__auto-width-item"
      tuiTextfieldSize="m"
    ></tui-input>
  </div>
  <div class="w-1/12">
    @if (
      place.controls.pallet.value &&
      place.controls.pallet.value !== PalletType.CART
    ) {
      <app-checkbox-labeled [formControl]="place.controls.hasBox"
        ><span class="text-sm">Коробки</span></app-checkbox-labeled
      >
    }
  </div>

  <div class="flex w-6/12 gap-x-2">
    @if (hasBox$ | async) {
      <div class="w-10/12" tuiGroup [collapsed]="true">
        <app-autocomplete
          label="Код"
          class="tui-group__inherit-item w-3/12 tui-group__auto-width-item"
          componentClass="tui-group__inherit-item"
          [labelOutside]="false"
          [formControl]="place.controls.packageType"
          [items]="packageTypes"
          [itemValue]="'value'"
          [itemText]="'value'"
        ></app-autocomplete>
        <tui-input
          [formControl]="packageName"
          class="w-5/12 tui-group__auto-width-item"
          tuiTextfieldSize="m"
          >Описание</tui-input
        >
        <tui-input
          [formControl]="packageSize"
          class="w-2/12 tui-group__auto-width-item"
          tuiTextfieldSize="m"
          >Размер</tui-input
        >
        <tui-input
          [formControl]="packageLimit"
          class="w-2/12 tui-group__auto-width-item"
          tuiTextfieldSize="m"
          >Лимит</tui-input
        >
      </div>
      <tui-input-number
        class="mr-0 ml-auto w-2/12"
        tuiTextfieldSize="m"
        [formControl]="place.controls.packageQuantity"
        >Кол-во упаковки</tui-input-number
      >
    }
  </div>
  <tui-input-number tuiTextfieldSize="m" [formControl]="place.controls.quantity"
    >Кол-во</tui-input-number
  >
  @if (!(last && index === 0)) {
    <button
      appearance="secondary"
      size="s"
      tuiIconButton
      icon="tuiIconTrash"
      (click)="remove.emit(index)"
    ></button>
  }

  @if (last) {
    <button
      (click)="create.emit()"
      size="s"
      tuiIconButton
      icon="tuiIconPlus"
    ></button>
  }
</div>
