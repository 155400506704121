import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
} from '@angular/core';
import { TuiButtonModule } from '@taiga-ui/core';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import {
  distinctUntilChanged,
  fromEvent,
  map,
  shareReplay,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TuiLetModule, TuiScrollService } from '@taiga-ui/cdk';
import { WINDOW } from '@shared/providers/window.provider';

@Component({
  selector: 'app-scroll-top-button',
  standalone: true,
  imports: [TuiButtonModule, AsyncPipe, TuiLetModule],
  templateUrl: './scroll-top-button.component.html',
  styleUrl: './scroll-top-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollTopButtonComponent implements OnInit {
  scrollY$ = fromEvent(this.doc, 'scroll').pipe(
    startWith(this.win.scrollY),
    distinctUntilChanged(),
    map(() => this.win.scrollY),
  );

  isTop$ = this.scrollY$.pipe(
    map(value => value < 200),
    shareReplay(),
  );

  scroll$ = new Subject<'top' | 'bottom'>();

  constructor(
    @Inject(DOCUMENT) private readonly doc: Document,
    @Inject(WINDOW) private readonly win: Window,
    private readonly destroyRef: DestroyRef,
    private readonly scrollService: TuiScrollService,
  ) {}

  ngOnInit() {
    this.scroll$
      .pipe(
        switchMap(value => {
          const position =
            value === 'top' ? 0 : this.doc.documentElement.scrollHeight;
          return this.scrollService.scroll$(this.win, position);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
