<tui-island
  class="bg-neutral-100"
  *tuiLet="invoicesByClient$ | async as invoices"
>
  <table
    tuiTable
    class="plain-table w-1/2 overflow-x-auto px-2 cursor-pointer"
    [columns]="columns"
  >
    <thead>
      <tr tuiThGroup>
        @for (header of tableHeaders; track 'value') {
          <th tuiTh>
            {{ header.name }}
          </th>
        }
      </tr>
    </thead>
    <tbody tuiTbody>
      <tr *ngFor="let invoice of invoices" tuiTr>
        @for (header of tableHeaders; track 'value') {
          <td *tuiCell="header.value" tuiTd>
            {{ invoice[header.value] }}
          </td>
        }
        <td *tuiCell="'cost'" tuiTd>
          {{ invoice.cost }} {{ invoice.currencyLetter }}
        </td>
      </tr>
    </tbody>
  </table>
</tui-island>
